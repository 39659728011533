import { MessagingVerificationStatus } from "@/enums/messaging";

export const welcomeText = "Welcome to GAIL Dashboard";

export const ROUTE_LABELS = {
  MISSING: "Oops! Page Not Found",
  DASHBOARD: "Dashboard",
  CONFIRMATION: "Welcome to GAIL",
  CALLS: "Calls",
  MESSAGES: "Messages",
  CONTACTS: "Contacts",
  PARTNERS: "Partners",
  KNOWLEDGE_BASE: "GAIL Brain",
  RECEPTIONIST: "Receptionist",
  SETTINGS: "Settings",
  CAMPAIGNS: "Campaigns",
  CALL_DETAILS: "Call Summary",
  CALL_SCRIPTS: "Scripts",
  SUPPORT_CENTER: "Support Center",
  RELEASE_NOTES: "Features & Releases",
  TRY_GAIL: "Try GAIL",
  START: "Where to Start",
};

export const CONFIRMATION_LABELS = {
  SUBTITLE: "we're so glad you're here",
  SKIP_ONBOARDING: "I Got This (Skip Onboarding Meeting)",
};

export const MISSING_LABELS = {
  SUBTITLE: "Looks like you've ventured too far...",
};

export const DASHBOARD_LABELS = {
  METRIC_TOTAL_LABEL: "Total Calls Handled",
  METRIC_SUPPORT_LABEL: "Support Calls Handled",
  METRIC_SALES_LABEL: "Sales Calls Handled",
  METRIC_AVAILABLE_LINES_LABEL: "Available Lines",
  METRIC_INBOUND_LINES_USED_LABEL: "Inbound Lines Used",
  METRIC_OUTBOUND_LINES_USED_LABEL: "Outbound Lines Used",
};

export const MESSAGE_LABELS = {
  TITLE: "Message Conversations",
  SUBTITLE: "View all the conversations GAIL has had with your customers",
  LOADING: "Loading Messages...",
  NOTHING: "No Messages Found",
};

export const MESSAGE_DETAILS_LABELS = {
  TITLE: "Message Conversation",
  SUBTITLE: "View the conversation between GAIL and your customer",
  LOADING: "Loading Message Conversation...",
};

export const CAMPAIGNS_LABELS = {
  SUBTITLE: "All campaigns managed by GAIL to save you time and money",
  CALLS_IN_QUEUE: "Calls in Queue",
  CALLS_COMPLETED: "Calls Completed",
  TOTAL_CALLS: "Total Calls",
  LOADING_CAMPAIGNS: "Loading Campaigns...",
  LOADING_LEADS: "Loading Contacts...",
  NO_CAMPAIGNS_FOUND: "Sorry, No Campaigns Found",
  NO_CAMPAIGNS: "You Should Create a Campaign!",
  CREATE_CAMPAIGN: "Create Campaign",
  SETUP_CAMPAIGN: "Setup Campaign",
  CREATE_CAMPAIGN_TITLE: "Create Campaign",
  UPDATE_CAMPAIGN_TITLE: "Update Campaign",
  CREATE_CAMPAIGN_SUB_TITLE: "Follow the steps below to create a new Campaign",
  UPDATE_CAMPAIGN_SUB_TITLE: "Make any changes to your Campaign you need",
  CAMPAIGN_DETAILS_TITLE: "Campaign Details",
  CAMPAIGN_DETAILS_SUB_TITLE_RESULTS: "Below are the results of your campaign",
  CAMPAIGN_DETAILS_SUB_TITLE_SCHEDULED: "Start your campaign when you're ready",
  CAMPAIGN_DETAILS_SUB_TITLE_DRAFT: "Please complete your campaign setup",
  UPLOAD_LANGUAGE: {
    uploads: {
      drag_drop: " or drag and drop",
      files_accepted: " files accepted",
    },
    links: {
      click_here: "Click to upload",
    },
  },
  DETAILS: {
    METRICS: {
      TOTAL: "Total Calls",
      CONNECTED: "Connected Calls",
      INTERESTED: "Leads Interested",
      COST: "Campaign Cost",
    },
  },
  TC_MODAL: {
    TITLE: "Agree to T&C",
    SUB_TITLE: "Before you continue, please review GAIL's Terms and Conditions",
    LINK: "Click here to review GAIL's T&C",
    AGREE: "I Agree",
    BACK: "Back",
  },
};

export const CONTACTS_LABELS = {
  TITLE: "Contacts",
  SUBTITLE: "View and manage all the contacts for your agency",
  LOADING: "Loading Contacts...",
  NOTHING_TITLE: "Oops, its empty!",
  NOTHING_SUBTITLE: "Looks like you haven't added any contacts yet",
};

export const CONTACT_LISTS_LABELS = {
  TITLE: "Contact Lists",
  SUBTITLE: "View and manage all the contact lists to be used in campaigns",
  LOADING: "Loading Contact Lists...",
  NOTHING_TITLE: "Oops, its empty!",
  NOTHING_SUBTITLE: "Looks like you haven't added any contact lists yet",
};

export const CONTACT_LIST_DETAILS_LABELS = {
  TITLE: "Contact List Details",
  TITLE_DYNAMIC: "Contact List - ",
  SUBTITLE: "View and manage all the contacts  for this contact list",
  LOADING: "Loading Contact List...",
};

export const SCRIPTS_LABELS = {
  TITLE: "GAIL Scripts",
  SUBTITLE:
    "GAIL Scripts help GAIL respond to your customers depending on the situation when making outbound calls via a campaign or through integration with our partners",
  LOADING: "Loading Scripts...",
  NOTHING: "No Scripts Found",
};

export const SCRIPT_LABELS = {
  LOADING_TITLE: "Loading Script",
  LOADING_SUBTITLE: "",
  LOADED_TITLE: "",
  LOADED_SUBTITLE: "Below you can modify the ",
  GREETING_LABEL: "Greeting",
  VOICEMAIL_LABEL: "Voicemail",
  GOODBYE_LABEL: "Goodbye",
  OBJECTIVE_LABEL: "Objective",
  POLICY_LABEL: "Policy",
  FAQ_LABEL: "FAQs",
  FAQ_EMPTY:
    "Add your first FAQ Below; these help GAIL answer common questions",
  UPDATE_BTN: "Update Script",
  BACK_BTN: "Back",
  TRY_BTN: "Try It Out",
};

export const SETTINGS_LABELS = {
  TITLE: "Account Settings",
  RECEPTIONIST: {
    TITLE: "Receptionist",
    DESCRIPTION: "GAIL helps your business manage inbound calls efficiently",
  },
  ASK: {
    TITLE: "Ask GAIL",
    DESCRIPTION:
      "GAIL is here to help you with any questions you may have about the calls it has handled",
  },
  START: {
    TITLE: "Welcome to GAIL",
    DESCRIPTION: "Let's get you started with GAIL",
  },
  SMART_SETUP: "GAIL Smart Setup",
  SMART_SETUP_DESCRIPTION:
    "Use the power of GAIL to help you setup your agency's experience",
  PARTNERS_TITLE: "Partner Integrations",
  SUBTITLE: "Below you can modify GAIL's experience to your liking",
  INTEGRATIONS: "Integrations",
  SECTIONS: {
    USER_PROFILE: {
      TITLE: "User Profile & Default Voice",
    },
    AGENCY_FAQ: {
      TITLE: "Agency Details",
      DESCRIPTION:
        "Your Agency & it's owners information which is used in many verifications and required for compliance",
      FAQS: {
        TITLE: "General FAQs",
        DESCRIPTION:
          "Teach GAIL how to respond when its asked the most common questions",
      },
      BUSINESS_INFO: {
        TITLE: "Agency Details",
        DESCRIPTION:
          "Let us know the core details of your agency to help GAIL better serve your customers",
      },
      AGENCY_CONTACT: {
        TITLE: "Setup GAIL Receptionist",
        DESCRIPTION:
          "Share with GAIL what is the best email and phone for your customers to reach you at",
      },
      CONFIRM_COVERAGES: {
        TITLE: "Confirm Your Coverages",
        DESCRIPTION:
          "Let GAIL know what coverages your agency is able to offer",
      },
      BUSINESS_HOURS: {
        TITLE: "Set Your Business Hours",
        DESCRIPTION:
          "Have GAIL know when your agency is open to better serve your customers",
      },
    },
    GAIL_NOTIFICATIONS: {
      TITLE: "GAIL Notifications",
      DESCRIPTION:
        "Let us know where to send notifications to keep you up to date with GAIL's activity",
    },
    GAIL_USER_ADMINISTRATION: {
      TITLE: "User Access",
      DESCRIPTION: "Add and remove people from access to GAIL Dashboard",
    },
    GAIL_SETTINGS: {
      TITLE: "GAIL Settings",
      DESCRIPTION:
        "Customize GAIL's settings to better fit your agency's needs",
    },
    BILLING: {
      TITLE: "Billing",
      DESCRIPTION:
        "Manage your billing information and view your billing history",
    },
    CONNECTED_SYSTEMS: {
      TITLE: "Connected Systems",
    },
  },
  AUTO_CRAWL: {
    TITLE: "Welcome to Smart Setup",
    DESCRIPTION:
      "Automatically setup your agency's GAIL experience just with your website and we will ask any questions we need to fill in the rest",
    START_BTN: "Smart Setup",
    CONTINUE_BTN: "Continue Smart Setup",
    MSG_COMPLETE:
      "Thank you for using the Smart Setup Onboarding Experience. If for any reason you have any issues please reach out to the GAIL team.",
    MSG_PROCESSED:
      "We have successfully processed your website and we are ready for you to continue your smart onboarding experience",
    WEBSITE_REQUIRED: "Please add your website in Settings to continue",
  },
};

export const CALL_DETAILS_LABELS = {
  SUBTITLE: "",
  INFORMATION: "Caller Information",
  RECORDING: "Call Recording",
  TRANSCRIPTION: "Call Transcript",
  TIMELINE: "Timeline",
};

export const COMPANY = {
  TITLE: "Company",
  QUOTE: {
    DISCOUNT_TITLE: "Add Discount",
    DISCOUNT_LABEL: "Discount",
    DETAILS: "Quote Details",
    CHANGE: "Change Discount",
    ADD: "Add Discount",
    QUANTITY: "Quantity",
    QTY: "Qty",
    TOTAL: "Total",
  },
  CURRENT_STATUS: "Current Status",
  DOCUMENTS: {
    TITLE: "Documents",
    DOWNLOAD: "Document Download",
    DOWNLOAD_READY: "Download Ready",
    DOWNLOAD_ERROR: "Download Error",
  },
};

export const GENERAL = {
  NA: "N/A",
  EDIT: "Edit",
  ADD: "Add",
  DELETE: "Delete",
  CANCEL: "Cancel",
  SAVE: "Save",
  START: "Start",
  CONTINUE: "Continue",
  CONFIRM: "Confirm",
};

export const TRANSFER_COPY = {
  MODAL_TITLE: "Transfer Rule",
  MODAL_MOBILE_WARNING: "We recommend you use a larger screen for this feature",
  FOR: {
    TITLE: "Caller",
    DESCRIPTION:
      "what type of customer and reason for their call should this apply",
  },
  WHAT: {
    TITLE: "Condition(s)",
    DESCRIPTION:
      "(optional) conditions should we apply to determine if this rule should be used",
  },
  WHEN: {
    TITLE: "Time",
    DESCRIPTION: "do these rules apply",
  },
  WHO: {
    TITLE: "Recipient",
    DESCRIPTION:
      "should be the recipient of the call when this rule is matched",
  },
};

export const TranslateHelper = (
  translateFunc: (key: string) => string,
  key: string | undefined
) => {
  let translatedValue = translateFunc((key || "").toLowerCase());
  if (translatedValue !== (key || "").toLowerCase()) {
    return translatedValue;
  }

  return key || "";
};
